@import "variables";

.home-page {
  .full-screen-header {
    h1 {
      letter-spacing: 2px;
      animation: top-to-bottom-fade-in 1s linear;
      -moz-animation: top-to-bottom-fade-in 1s linear; /* Firefox */
      -webkit-animation: top-to-bottom-fade-in 1s linear; /* Safari and Chrome */
      -o-animation: top-to-bottom-fade-in 1s linear; /* Opera */
    }

    @keyframes top-to-bottom-fade-in {
      from {
        margin-top: -150px;
        opacity:0;
      }
      to {
        margin-top: 0;
        opacity:1;
      }
    }
    @-moz-keyframes top-to-bottom-fade-in { /* Firefox */
      from {
        margin-top: -150px;
        opacity:0;
      }
      to {
        margin-top: 0;
        opacity:1;
      }
    }
    @-webkit-keyframes top-to-bottom-fade-in { /* Safari and Chrome */
      from {
        margin-top: -150px;
        opacity:0;
      }
      to {
        margin-top: 0;
        opacity:1;
      }
    }
    @-o-keyframes top-to-bottom-fade-in { /* Opera */
      from {
        margin-top: -150px;
        opacity:0;
      }
      to {
        margin-top: 0;
        opacity:1;
      }
    }
  }

  .embedded-video {
    max-width: 800px;
    margin: 0 auto;

    iframe {
      width: 90%;
      height: 30em;

      @media (max-width: $small-size) {
        height: 12em;
      }
    }
  }

  .section {
    &.gallery {
      padding-left: 0;
      padding-right: 0;

      .instagram-item {
        position: relative;
        cursor: pointer;

        .image-container {
          width: 100%;
          height: 320px;
          background-position: center;
          background-size: cover;
        }
      }
    }

    .you-can-travel {
      .icon-holder {
        font-size: 4em;
        height: 85px;

        .fa-mobile-phone {
          font-size: 1.3em;
        }
      }

      .description {
        padding: 0 4em;
      }
    }

    .featured-trip {
      padding: 7px;
      overflow: hidden;

      .promo {
        transform: rotate(-45deg);
        background: orange;
        text-align: center;
        width: 140px;
        position: absolute;
        z-index: 2;
        top: 25px;
        left: -30px;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .btn-home-custom {
      font-size: $fs-m;
      border: 2px solid #444;
      padding: .8em 1em;
      text-transform: uppercase;
      height: 47px;
      width: 163px;
      font-weight: 700;
      color: #444;

      &.btn-see-more-trips {
        background-color: #e62117;
        color: #fff;
        border: none;
      }

      &.btn-youtube-custom {
        margin-top: 30px;
        background-color: #e62117;
        border: 2px solid #fff;
        color: #fff;
      }

      &.btn-instagram-custom {
        margin-top: 30px;
        background-color: #2a5b83;
        border: 2px solid #fff;
        color: #fff;
      }

      &:hover {
        background-color: #f6f6f6;
        border: 2px solid #444;
        color: #444;
      }
    }

    &.video-memories {
      background: url("../images/home/bgsand.jpg") no-repeat;
      background-size: cover;

      .memory-item {
        a {
          display: block;
          width: 100%;
          height: auto;
          position: relative;

          &:hover {
            text-decoration: none;
          }

          .video-holder {
            width: 100%;
            height: auto;
          }

          .overlay {
            width: 100%;
            height: 80px;
            position: absolute;
            top: 45px;
            left: 0;
            margin: 0 auto;
          }

          .home-video-title {
            background-color: #fff;
            height: 50px;
            width: 100%;
            color: #000;
            font-size: $fs-l;
            font-family: "MuseoSansCyrl-500", sans-serif;
          }
        }
      }
    }
  }

  @media (max-width: $small-size) {
    .full-screen-header {
      background: url("../images/home/home.jpg") no-repeat center center;
      background-size: cover;
    }
  }
}