.home-page .full-screen-header h1 {
  letter-spacing: 2px;
  animation: top-to-bottom-fade-in 1s linear;
  -moz-animation: top-to-bottom-fade-in 1s linear;
  /* Firefox */
  -webkit-animation: top-to-bottom-fade-in 1s linear;
  /* Safari and Chrome */
  -o-animation: top-to-bottom-fade-in 1s linear;
  /* Opera */
}

@keyframes top-to-bottom-fade-in {
  from {
    margin-top: -150px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@-moz-keyframes top-to-bottom-fade-in {
  /* Firefox */
  from {
    margin-top: -150px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes top-to-bottom-fade-in {
  /* Safari and Chrome */
  from {
    margin-top: -150px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

@-o-keyframes top-to-bottom-fade-in {
  /* Opera */
  from {
    margin-top: -150px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}

.home-page .embedded-video {
  max-width: 800px;
  margin: 0 auto;
}

.home-page .embedded-video iframe {
  width: 90%;
  height: 30em;
}

@media (max-width: 768px) {
  .home-page .embedded-video iframe {
    height: 12em;
  }
}

.home-page .section.gallery {
  padding-left: 0;
  padding-right: 0;
}

.home-page .section.gallery .instagram-item {
  position: relative;
  cursor: pointer;
}

.home-page .section.gallery .instagram-item .image-container {
  width: 100%;
  height: 320px;
  background-position: center;
  background-size: cover;
}

.home-page .section .you-can-travel .icon-holder {
  font-size: 4em;
  height: 85px;
}

.home-page .section .you-can-travel .icon-holder .fa-mobile-phone {
  font-size: 1.3em;
}

.home-page .section .you-can-travel .description {
  padding: 0 4em;
}

.home-page .section .featured-trip {
  padding: 7px;
  overflow: hidden;
}

.home-page .section .featured-trip .promo {
  transform: rotate(-45deg);
  background: orange;
  text-align: center;
  width: 140px;
  position: absolute;
  z-index: 2;
  top: 25px;
  left: -30px;
  font-weight: bold;
  font-size: 18px;
}

.home-page .section .btn-home-custom {
  font-size: 16px;
  border: 2px solid #444;
  padding: .8em 1em;
  text-transform: uppercase;
  height: 47px;
  width: 163px;
  font-weight: 700;
  color: #444;
}

.home-page .section .btn-home-custom.btn-see-more-trips {
  background-color: #e62117;
  color: #fff;
  border: none;
}

.home-page .section .btn-home-custom.btn-youtube-custom {
  margin-top: 30px;
  background-color: #e62117;
  border: 2px solid #fff;
  color: #fff;
}

.home-page .section .btn-home-custom.btn-instagram-custom {
  margin-top: 30px;
  background-color: #2a5b83;
  border: 2px solid #fff;
  color: #fff;
}

.home-page .section .btn-home-custom:hover {
  background-color: #f6f6f6;
  border: 2px solid #444;
  color: #444;
}

.home-page .section.video-memories {
  background: url("../images/home/bgsand.jpg") no-repeat;
  background-size: cover;
}

.home-page .section.video-memories .memory-item a {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.home-page .section.video-memories .memory-item a:hover {
  text-decoration: none;
}

.home-page .section.video-memories .memory-item a .video-holder {
  width: 100%;
  height: auto;
}

.home-page .section.video-memories .memory-item a .overlay {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 45px;
  left: 0;
  margin: 0 auto;
}

.home-page .section.video-memories .memory-item a .home-video-title {
  background-color: #fff;
  height: 50px;
  width: 100%;
  color: #000;
  font-size: 18px;
  font-family: "MuseoSansCyrl-500", sans-serif;
}

@media (max-width: 768px) {
  .home-page .full-screen-header {
    background: url("../images/home/home.jpg") no-repeat center center;
    background-size: cover;
  }
}

